import React, { useState } from "react"
import axios from "axios"
import { getBackendUrl } from "./utils"

export const myContext = React.createContext()

const Provider = props => {
  const [posts, setPosts] = useState([])
  const [newsIsLoading, setNewsIsLoading] = useState(true)
  const [newsError, setNewsError] = useState("")
  const [userSubscribed, setUserSubscribed] = useState(false)
  const [subscribeComponent, setSubscribeComponent] = useState("")
  const [userSubscribedError, setUserSubscribedError] = useState("")

  const fetchMediumPosts = async () => {
    try {
      axios
        .get(getBackendUrl("getAll"))
        .then(response => {
          const result = response.data
          setNewsIsLoading(false)

          if (result.status === 200) {
            setPosts(result.data.items)
          }

          if (result.status === 403) {
            setNewsError(
              "Sorry could not fetch news from medium, please try again."
            )
          }
        })
        .catch(err => {
          setNewsIsLoading(false)
          setNewsError(err)
        })
    } catch (error) {
      setNewsIsLoading(false)
      setNewsError(error)
    }
  }

  const subscribeToNewsLetter = async (payload, component) => {
    setSubscribeComponent(component)

    try {
      const response = await axios
        .post(getBackendUrl("subscribe"), payload)
        .then(async response => {
          await setUserSubscribed(true)
          return response
        })
        .catch(async function (error) {
          await setUserSubscribed(false)
          await setUserSubscribedError(error)
          return Promise.reject(error)
        })
    } catch (error) {
      await setUserSubscribed(false)
      await setUserSubscribedError(error)
      console.log(error)
    }

    setTimeout(() => {
      setUserSubscribed(false)
      setSubscribeComponent("")
      setUserSubscribedError("")
    }, 5000) // reset after 5 secs
  }

  return (
    <myContext.Provider
      value={{
        newsIsLoading,
        posts,
        newsError,
        subscribeToNewsLetter,
        userSubscribed,
        subscribeComponent,
        userSubscribedError,
        fetchMediumPosts,
      }}
    >
      {props.children}
    </myContext.Provider>
  )
}

export default ({ element }) => <Provider>{element}</Provider>
